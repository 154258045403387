<template>
  <div class="auth-wrapper auth-v1">
    <div class="auth-inner">
      <v-card class="auth-card">
        <v-card-title class="d-flex align-center justify-center py-7">
          <router-link
            to="/"
            class="d-flex align-center"
          >
            <v-img
              :src="appLogo"
              max-height="70px"
              alt="logo"
              contain
              class="me-3 "
            ></v-img>

          </router-link>
        </v-card-title>

        <v-card-text>
          <p class="text-2xl font-weight-semibold text--primary mb-2">
            {{ $t("Forgot password?") }} 🔒
          </p>
          <p class="mb-2">
            {{ $t("Enter your email address and we will send you instructions to reset your password.") }}
          </p>
        </v-card-text>

        <!-- login form -->
        <v-card-text>
          <v-form
            ref="loginForm"
            @submit.prevent="handleFormSubmit"
          >
            <v-text-field
              v-model="email"
              :rules="[validators.required, validators.emailValidator]"
              outlined
              :label="$t('Email')"
              :placeholder="$t('Email')"
              :error-messages="errorMessages.email"
            ></v-text-field>

            <v-btn
              block
              color="primary"
              type="submit"
              :loading="isLoading"
            >
              {{ $t("Send reset link") }}
            </v-btn>
          </v-form>
        </v-card-text>

        <v-card-actions class="d-flex justify-center align-center">
          <router-link
            :to="{name:'auth-login'}"
            class="d-flex align-center text-sm"
          >
            <v-icon
              size="24"
              color="primary"
            >
              {{ icons.mdiChevronLeft }}
            </v-icon>
            <span>{{ $t("Back to login") }}</span>
          </router-link>
        </v-card-actions>
      </v-card>
    </div>
    <v-snackbar
      v-model="isSnackbarBottomVisible"
      :color="snackBarColor"
      :light="$vuetify.theme.dark"
      timeout="5000"
    >
      {{ snackBarMessage }}
    </v-snackbar>
    <!-- background triangle shape  -->
    <img
      class="auth-mask-bg"
      height="190"
      :src="require(`@/assets/images/misc/mask-${$vuetify.theme.dark ? 'dark':'light'}.png`)"
    />

    <!-- tree -->
    <v-img
      class="auth-tree"
      width="247"
      height="185"
      src="@/assets/images/misc/tree.png"
    ></v-img>

    <!-- tree  -->
    <v-img
      class="auth-tree-3"
      width="377"
      height="289"
      src="@/assets/images/misc/tree-3.png"
    ></v-img>
  </div>
</template>

<script>
import {
  mdiChevronLeft,
} from '@mdi/js'
import { ref, getCurrentInstance  } from '@vue/composition-api'
import themeConfig from '@themeConfig'
import { required, emailValidator } from '@core/utils/validation'
import axios from '@axios'

export default {
  setup() {
    const isPasswordVisible = ref(false)
    const vm = getCurrentInstance().proxy
    const email = ref('')

    // Template Ref
    const loginForm = ref(null)

    const isSnackbarBottomVisible = ref(false)
    const snackBarMessage = ref('')
    const snackBarColor = ref('success')
    const isLoading = ref(false)
    const errorMessages = ref({ })

    const handleFormSubmit = () => {
      const isFormValid = loginForm.value.validate()

      if (!isFormValid) return

      /*
        1. Make HTTP request to get accessToken
        2. Store received token in localStorage for future use
        3. Make another HTTP request for getting user information
        4. On successful response of user information redirect to home page

        ? We have use promise chaining to get user data from access token
        ? Promise Chaining: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Guide/Using_promises#chaining
      */
      isLoading.value = true
      axios({
        method: 'post',
        url: '/forgot-password',
        data: { email: email.value },
        // eslint-disable-next-line no-unused-vars
        validateStatus: status => true,
      }).catch(error => {
        // this.loading = false
        isLoading.value = false
        console.log(error)
        // eslint-disable-next-line consistent-return
      }).then(response => {
        if (response.data.error || response.data.errors || (response.data.status && response.data.status === 'false')) {
          if (response.data.errors) {
            // this.toast(this.$t('These credentials do not match our records'), this.$vuetify.theme.currentTheme.error)
            errorMessages.value = { ...response.data.errors }
          } else {
            isSnackbarBottomVisible.value = true
            snackBarMessage.value = response.data.message
            snackBarColor.value = vm.$vuetify.theme.currentTheme.error

            // this.toast(response.data.message, this.$vuetify.theme.currentTheme.error)
          }

          return false
        }

        isSnackbarBottomVisible.value = true
        snackBarColor.value = vm.$vuetify.theme.currentTheme.success
        snackBarMessage.value = 'Nous vous avons envoyé par email un lien pour réinitialiser votre mot de passe'
      }).then(() => {
        isLoading.value = false
      })
        .catch(error => {
          // TODO: Next Update - Show notification
          console.error('Oops, Unable to login!')
          console.log('error :>> ', error.response)
          errorMessages.value = error.response.data.error
        })
    }

    return {
      handleFormSubmit,
      isSnackbarBottomVisible,
      snackBarMessage,
      snackBarColor,
      email,
      isLoading,
      isPasswordVisible,
      errorMessages,
      validators: { required, emailValidator },

      // themeConfig
      appName: themeConfig.app.name,
      appLogo: themeConfig.app.logo,

      icons: {
        mdiChevronLeft,
      },

      // Template Refs
      loginForm,
    }
  },
}
</script>

<style lang="scss">
@import '@core/preset/preset/pages/auth.scss';
</style>
